import React from 'react'
import { graphql, useStaticQuery } from "gatsby"
import UnbalancedImageGrid from '../willoughby/unbalanced-image-grid'

export default function UnbalancedImageGridThree(props) {
  const data = useStaticQuery(graphql`
  {
    cropped: allFile(
      filter: {absolutePath: {regex: "/year-in-review/2023/cropped/unbalanced/"}, ext: {eq: ".jpg"}}
      sort: {fields: name, order: ASC}
    ) {
      nodes {
        name
        childImageSharp {
          gatsbyImageData(
            height: 400
            placeholder: BLURRED
            formats: [AUTO, WEBP]
          )
        }
      }
    }
    fullsize: allFile(
      filter: {absolutePath: {regex: "/year-in-review/2023/uncropped/unbalanced/"}, ext: {eq: ".jpg"}}
      sort: {fields: name, order: ASC}
    ) {
      nodes {
        name
        childImageSharp {
          gatsbyImageData(
            width: 1600
            placeholder: BLURRED
            formats: [AUTO, WEBP]
          )
        }
      }
    }
  }`)

  return (
    <UnbalancedImageGrid content={props.content} queryData={data} flexBasisArray={props.flexBasisArray} />
  )
};
