import React from 'react'
import { StaticImage } from "gatsby-plugin-image"

export default function BabyImageThree(props) {

  const imageStyle = {
    float: 'right',
    margin: '10px',
    width: '28%',
    minWidth: '150px',
  }

  return (
    <StaticImage src="../images/year-in-review/2023/cropped/ultrasound.jpg" alt="A 20 week ultrasound image of baby number 2." className="yir-single-image" style={imageStyle} />
  )
}
